.table>tbody>tr>td,
.table>tbody>tr>th {
  border-top: none;
}

.table>tbody>tr>td,
.table>tbody>tr>th {
  border-bottom: 1px solid #e1e5eb;
}

.action-column {
  text-align: right;
}

.action-column i.material-icons {
    font-size: 18px;
}

.action-column .btn.btn-pill {
  border-radius: 50%;
  padding: 6px 8px;
  margin-right: 4px;
}

.action-column .btn.btn-pill:hover i.material-icons {
  color: #fff !important;
}

.action-column .btn.btn-pill:disabled i.material-icons {
  color: inherit !important;
}