.uploader {
    display: flex;
    color: gray;
    margin: 32px;

    i {
        font-size: 48px !important;
    }

    .drop-area {
        border: 1px dashed #ddd;
        border-radius: 4px;
        cursor: pointer;
        padding: 32px;
        text-align: center;
        width: 100%;
        background-color: #f1f1f1;
    }

    .send-button {
        background-color: #6f2c91;
        color: white;
        text-transform: capitalize;
    }

}