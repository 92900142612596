.details-action-bar {
    padding: 20px;
    border-width: 1px 1px 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-left-style: solid;
    border-top-color: rgb(230, 233, 236);
    border-right-color: rgb(230, 233, 236);
    border-left-color: rgb(230, 233, 236);
    border-image: initial;
    box-shadow: 0 2px 0 rgb(90 97 105 / 11%), 0 4px 8px rgb(90 97 105 / 12%), 0 10px 10px rgb(90 97 105 / 6%), 0 7px 70px rgb(90 97 105 / 10%);
    border-radius: 5px 5px 0px 0px;
    border-bottom-style: initial;
    border-bottom-color: initial;
    background: rgb(255, 255, 255);
    margin-bottom: 8px;
    border-radius: 8px;
}


.details-navlink {
    margin-right: 12px;
    cursor: pointer;
}