.card-extras {
    max-width: 426px;
}

.auth-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
}

.auth-buttons button {
    width: 100%;
}


