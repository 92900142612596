.back-button {
  position: absolute;
  right: 2rem;
  top: 2rem;
  font-size: 2rem;
  cursor: pointer;
}
 
.table>tbody>tr>td, .table>tbody>tr>th {
  vertical-align: middle;
}

.btn.btn-pill:disabled i.material-icons {
  color: inherit !important;
}

.center-text {
  text-align: center;
}