.rightbox {
    padding: 0em 2.3rem;
    height: 100%;
  }
  
  .rb-container {
    margin: auto;
    display: block;
    position: relative;
  }
  
  .rb-container ul.rb {
    margin: 2.5em 0;
    padding: 0;
    display: inline-block;
  }
  
  .rb-container ul.rb li {
    list-style: none;
    margin: auto;
    min-height: 50px;
    border-left: 1px dashed #6f2c91;
    padding: 0 0 20px 30px;
    position: relative;
  }
  
  .rb-container ul.rb li:last-child {
    border-left: 0;
  }
  
  .rb-container ul.rb li::before {
    position: absolute;
    left: -11px;
    top: 3px;
    content: " ";
    border: 8px solid rgba(255, 255, 255, 1);
    border-radius: 500%;
    background: #fff;
    height: 20px;
    width: 20px;
    transition: all 500ms ease-in-out;
  }
  
  .rb-container ul.rb li::before {
    border-color: #6f2c91;
    transition: all 1000ms ease-in-out;
  }
  
  ul.rb li .timestamp {
    color: #6f2c91;
    position: relative;
    width: 100px;
    font-size: 12px;
  }
  
  
  .back-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 2rem;
    cursor: pointer;
}
 