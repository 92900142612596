/* Overview classes */
.change-view {
    position: absolute;
    right: 2rem;
    top: 6rem;
}

.change-view button {
    border-radius: 0;
    font-size: 1.2rem;
    padding: 0.1rem 0.45rem;
}

.activated {
    background-color: #5A6169 !important;
    color: #fff !important;
}