.client-reference {
    font-size: 1rem;
}


.origin-destination {
    display: grid;
    grid-template-columns: 40% 20% 40%;
    gap: 4px;
}

.origin-destination .title {
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
}

.origin-destination .subtitle {
    font-size: 0.65rem;
}

.origin-destination .transportation-icon {
    font-size: 1.6rem;
    text-align: center;
    margin-top: 20%;
    color: #868e96;
}

.name-letters {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.p-mb-0 p {
    margin-bottom: 0.3rem;
}

.p-mt-2 p {
    margin-top: -0.2rem;
}